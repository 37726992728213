
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getQuestionnaires, createSession, getLastSession } from '@/firebase';
import Questionnaire from '@/models/Questionnaire';
import Hashids from 'hashids'

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup (props, context)  
  {
    const warning = ref<string>();
    const loading = ref<boolean>(false);
    const sessionCreated = ref<boolean>(false);

    const username = ref<string>("");
    const questionnaire_id = ref<string>("");
    const access_code = ref<string>("");

    const createButton = ref<HTMLButtonElement>();
    const questionnaires = ref<Questionnaire[]>([]);

    const router = useRouter();

    getQuestionnaires().then( (data) => {
        if (data)
        {
          data.forEach((questionnaireData) => {
              let questionnaire = new Questionnaire({ 'id' : questionnaireData.id, 'title' : questionnaireData.data().title, sections: JSON.parse(questionnaireData.data().sections), current_section_idx: 0, current_question_idx: 0, valueGroups: {}, status: "" });
              questionnaires.value.push(questionnaire);
          });          
        }
    });

    const createClicked = () => 
    {
      loading.value = true;

      getLastSession().then ((data) => {
        if (data)
        {        
            const hashids = new Hashids('', 4, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');
            let new_id = data.id + 1;

            createSession(username.value, questionnaire_id.value, new_id, hashids.encode(new_id)).then((data) => {
                                      
                access_code.value = hashids.encode(new_id);                                  
                loading.value = false;
                sessionCreated.value = true;
            });
          
        }
        else
          warning.value = "There was an error creating the session.";

      });
        
    };

    return { warning, loading, questionnaires, createButton, username, questionnaire_id, access_code, sessionCreated, createClicked }
  }
});
