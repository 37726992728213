
import { defineComponent, ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup (props, context)  
  {
    const warning = ref<string>();
    const loading = ref<boolean>(false);

    
    const email = ref<string>('');
    const password = ref<string>('');
    const loginButton = ref<HTMLButtonElement>();
    const sessionCodeInput = ref<HTMLInputElement>();

    const router = useRouter();

    const loginClicked = () => {
        loading.value = true;
        signInWithEmailAndPassword(getAuth(), email.value, password.value).then((data) => {
            router.replace('/admin');
        }).catch((ex) => 
        {          
            warning.value = ex.message;
            loading.value = false;
        });
    };

    onBeforeMount( () => {
        getAuth().onAuthStateChanged((user) => {
          if (user)
          {
            router.replace('/admin');
          }
        })
    });

    return { email, password, warning, loading, loginButton, sessionCodeInput, loginClicked }
  }
});
