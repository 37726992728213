
import { defineComponent, onBeforeMount, ref } from 'vue';
import { getAuth, signOut } from 'firebase/auth';
import { useRouter, useRoute } from 'vue-router';
import Questionnaire from '@/models/Questionnaire';
import { getQuestionnaires, getAllSessions } from '@/firebase';
import QuestionnaireSession from '@/models/QuestionnaireSession';
import { DocumentData } from '@firebase/firestore/dist/lite';
import Section from '@/models/Section';
import Question from '@/models/Question';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();

    const sessions = ref<QuestionnaireSession[]>([]);
    const showModal = ref<boolean>(false);
    const selectedResult = ref<[]>([]);
    
    let questionnaireIDMap = [];

    onBeforeMount( () => {
        getAuth().onAuthStateChanged((user) => {
          if (!user)
          {
            router.replace('/login');
          }
          else if (route.path == '/login')
          {
            console.log('else');
          }
        
        })
    });

    const logoutClicked = () =>
    {
        signOut(getAuth());
    };

    getQuestionnaires().then( (data) => {
        if (data)
        {
          data.forEach((questionnaireData) => {
              let questionnaire = new Questionnaire({ 'id' : questionnaireData.id, 'title' : questionnaireData.data().title, sections: JSON.parse(questionnaireData.data().sections), current_section_idx: 0, current_question_idx: 0, valueGroups: {}, status: "" });                      
              questionnaireIDMap[questionnaire.id] = questionnaire;
          });          

           getAllSessions().then((data) => {
          
            data.forEach(function(sessionData: DocumentData) {
                  let session = new QuestionnaireSession({ 'id' : '', 'user_name' : sessionData.user_name, 'questionnaire_id' : sessionData.questionnaire_id, 'created_date' : sessionData.created_date, 'access_code' : sessionData.access_code, 'valueGroups' : sessionData.valueGroups, 'questionnaireData' : sessionData.questionnaireData });
                  sessions.value.push(session);
            });

          });

        }
    });

    const getQuestionnaireName = (questionnaireID: string) => 
    {
      return questionnaireIDMap[questionnaireID].title;
    }

    const formatDate = (seconds: number) =>
    {      
        return new Date(seconds*1000).toLocaleString();      
    }

    const viewResultsForSession = (session: QuestionnaireSession) => {
              
        if (session.valueGroups && session.valueGroups!.length > 0)
          selectedResult.value = session.valueGroups;
        else
        {
          let questionnaireData = JSON.parse(session.questionnaireData);      
          let questionnaire = new Questionnaire({ 'id' : questionnaireData.id, 'title' : questionnaireData.title, sections: questionnaireData.sections, current_section_idx: 0, current_question_idx: 0, valueGroups: {}, status: "" });
          
          let sections = questionnaire.sections;

          let value_groups: { [group: string]: number} = {};

          sections.forEach((section: Section) => 
          {
            section.questions.forEach((question: Question) => 
            {
              if (question.selectedAnswer)
              {
                if (question.value_group in value_groups)
                    value_groups[question.value_group] += question.selectedAnswer.value;
                else
                    value_groups[question.value_group] = question.selectedAnswer.value;            
              }
            });
          });

          if (session.valueGroups)
            selectedResult.value = JSON.parse(JSON.stringify(value_groups));
        }

        showModal.value = true;
    }

    const closeModal = () =>
    {
        showModal.value = false;
    }

    return { sessions, selectedResult, showModal, logoutClicked, closeModal, formatDate, getQuestionnaireName, viewResultsForSession }
  },
})
